import { BASE_URL, BEYOND_CORE_ASSORTMENT, CATEGORY_BRAND_MAPPING, EDGE_ECOMM, GET_BP, INPUT_FORMS } from "../../constant/apiEndpoints";
import { IBeyondRawData, IDateListItem, IKBI, IKBIBrandCategory, IKBIValue, KBIInputState } from "../../reducer/ECommRetail/_interfaces";

export const createBeyondKBIInputValues = (kbis:IKBIBrandCategory[], priodTitle:string, countryTitle:string, data:any, kbiState:KBIInputState, beyondRawTable:IBeyondRawData[]):KBIInputState => {
    const parts = priodTitle.split("-");
    const month = parts[0];
    const year = Number(parts[1]);
    let kbi:IKBIBrandCategory[] = [];
    const kbiD = kbis.map(obj => ({ ...obj }));

    kbiD.forEach((val) => {
        if(val?.brand_owners === "" && val.category?.includes("NSR")){
            val.brand_owners = "NSR"
            val.category = val.category.replace("NSR", "").trim()
        } else if (val?.brand_owners === "" && val.category?.includes("Volume")){
            val.brand_owners = "Volume";
            val.category = val.category.replace("Volume", "").trim()
        }
        kbi.push(val)
    });

    let filterWithCountrymonthyear = beyondRawTable.filter(val => val.country === countryTitle && val.month === month && val.year === year)

    for (let i = 0; i < kbis.length; i++) {
        let kbi_obj = data.find((x:any) => x.kbi === kbis[i].kbi_id);
        
        let kbi_value = 0;
        
        if (kbi_obj) {
            if (kbi_obj.kbi_value) {
                kbi_value = kbi_obj.kbi_value;
            }
        } else {
            let kbi_obj_raw = filterWithCountrymonthyear.find((x) => x.brand_owner === kbi[i].brand_owners && x.category === kbi[i].category); 
            
            if (kbi_obj_raw) {
                kbi_value = kbi_obj_raw.value;
                if (kbi_value == null) {
                    kbi_value = 0;
                }
            }

        } 

        kbiState[kbis[i].kbi_id] = kbi_value;
    }

    return kbiState;
}

export const createKBIInputValues = (kbis:IKBIValue[], kbiMaster:IKBI[]) => {
    let kbiState:KBIInputState = {};
    let comment = "";
    let lastSubmittedAt = "";
    let lastSubmittedBy = "";

    if (kbis.length !== 0) {
        kbiMaster.forEach((kbi) => {
            const kbi_obj = kbis.find((x) => x.kbi === kbi.kbi_id);
            kbiState[kbi.kbi_id] = kbi_obj?.kbi_value || 0;
        });
      
        const firstKBI:IKBIValue = kbis[0];

        lastSubmittedAt = firstKBI.added_at; //Setting Last Submitted At
        lastSubmittedBy = firstKBI.added_by; //Setting Last Added At
        comment = firstKBI.comment; //Setting Comment
    }

    return {
        lastSubmittedAt,
        lastSubmittedBy,
        comment,
        kbiState
    }
}

export const createLoadDataEndpoints = (formId:string, periodId:string, countryId:string, periods:IDateListItem[] = []) => {
    const selected_date = periods.findIndex((x) => x.id === parseInt(periodId)) || 0;

    const period = periods[selected_date];

    const categoryBrandURL =
        BASE_URL
        + INPUT_FORMS
        + EDGE_ECOMM
        + CATEGORY_BRAND_MAPPING
        + `?selected_form=${formId}&selected_country=${countryId}`;

    const beyondCoreAssortmentURL =
        BASE_URL
        + INPUT_FORMS
        + EDGE_ECOMM
        + BEYOND_CORE_ASSORTMENT
        + `?selected_form=${formId}&selected_country=${countryId}&selected_year=${period?.year}&selected_period=${period?.month}`;

    const beyondCoreAssortmentPrevPeriodURL =
        BASE_URL
        + INPUT_FORMS
        + EDGE_ECOMM
        + BEYOND_CORE_ASSORTMENT
        + `?selected_form=${formId}&selected_country=${countryId}&selected_year=${periods[selected_date + 1]?.year}&selected_period=${periods[selected_date + 1]?.month}`;

    const kbiURL =
        BASE_URL
        + INPUT_FORMS
        + EDGE_ECOMM
        + `?selected_form=${formId}&selected_country=${countryId}&selected_year=${period.year}&selected_period=${period.month}`;

    const kbiPrevPeriodURL =
        BASE_URL
        + INPUT_FORMS
        + EDGE_ECOMM
        + `?selected_form=${formId}&selected_country=${countryId}&selected_year=${periods[selected_date + 1]?.year}&selected_period=${periods[selected_date + 1]?.month}`;


    const businessPlanURL =
        BASE_URL
        + INPUT_FORMS
        + EDGE_ECOMM
        + GET_BP
        + `?selected_form=${formId}&selected_country=${countryId}&selected_year=${period?.year}&selected_period=${period?.month}&period_name=${period?.title.split('-')[0]}`;

    return {
        categoryBrandURL,
        beyondCoreAssortmentURL,
        beyondCoreAssortmentPrevPeriodURL,
        kbiURL,
        kbiPrevPeriodURL,
        businessPlanURL
    }
}