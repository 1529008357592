import { Card, Col, Row, Spin, Typography } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as actions from "../../../../actions/eComm/eCommRetailFSAActions";
import * as selectors from "../../../../reducer/ECommRetail/selectors";
import { IBusinessPlan, IKBI, KBIInputState } from "../../../../reducer/ECommRetail/_interfaces";

type IProps = {
    inputDisabled: boolean;
    prevPeriodTitle: string;
}

const KBICard:FC<IProps> = ({inputDisabled, prevPeriodTitle}) => {
    const dispatch = useDispatch();

    const kbiMaster:IKBI[] = useSelector(selectors.kbiMaster);
    const kbiInputUnedited = useSelector(selectors.kbiMasterUnedited);
    const kbiValuesLoading:boolean = useSelector(selectors.kbiValuesLoading);
    const kbiValues:KBIInputState = useSelector(selectors.kbiValues);
    const kbiValuesPrevPeriod:KBIInputState = useSelector(selectors.kbiValuesPrevPeriod);
    const businessPlan:IBusinessPlan = useSelector(selectors.businessPlan);


    const [totalVolumeValue, setTotalVolumeValue] = useState<number>(0);
    const [totalNSRValue, setTotalNSRValue] = useState<number>(0);
    const [totalVolumePrevValue, setTotalVolumePrevValue] = useState<number>(0);
    const [totalNSRValuePrev, setTotalNSRValuePrev] = useState<number>(0);

    const [totalBPVolume, setTotalBPVolume] = useState<number>(0);
    const [totalBPNSR, setTotalBPNSR] = useState<number>(0);

    useEffect(() => {
        const { totalNSR, totalNSRPrev, totalNSRBP } = getTotalNSR();
        const { totalVolume, totalVolumePrev, totalVolumeBP } = getTotalVolume();

        setTotalVolumeValue(totalVolume);
        setTotalNSRValue(totalNSR);

        setTotalVolumePrevValue(totalVolumePrev);
        setTotalNSRValuePrev(totalNSRPrev);
        
        setTotalBPVolume(totalVolumeBP);
        setTotalBPNSR(totalNSRBP);
    }, [kbiValues, kbiValuesPrevPeriod, businessPlan]);

    //Dynamic Input onChange Handler
    const inputOnChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const re = /^[0-9]*\.?[0-9]*$/;
        const value = e.target.value;
        const name = e.target.name;

        if (value === "" || re.test(value)) {
            dispatch(actions.setKBIInputValues({...kbiValues, [name]: value} as any));
            dispatch(actions.setKBIUnedited({ ...kbiInputUnedited, [name]: value }));
        }
    };

    console.log({
        totalBPVolume,
        totalBPNSR
    })

    const getTotalNSR = () => {
        let totalNSR = 0;
        let totalNSRPrev = 0;
        let totalNSRBP = 0;

        if (kbiValues["KEECOM001"]) {
            totalNSR = totalNSR + parseFloat(String(kbiValues["KEECOM001"]));
            totalNSRPrev = totalNSRPrev + parseFloat(String(kbiValuesPrevPeriod["KEECOM001"]));
            totalNSRBP = totalNSRBP + businessPlan?.["KEECOM001"]?.value;
        }
        if (kbiValues["KEECOM002"]) {
            totalNSR = totalNSR + parseFloat(String(kbiValues["KEECOM002"]));
            totalNSRPrev = totalNSRPrev + parseFloat(String(kbiValuesPrevPeriod["KEECOM002"]));
            totalNSRBP = totalNSRBP + businessPlan?.["KEECOM002"]?.value;
        }
        if (kbiValues["KEECOM003"]) {
            totalNSR = totalNSR + parseFloat(String(kbiValues["KEECOM003"]));
            totalNSRPrev = totalNSRPrev + parseFloat(String(kbiValuesPrevPeriod["KEECOM003"]));
            totalNSRBP = totalNSRBP + businessPlan?.["KEECOM003"]?.value;
        }
        if (kbiValues["KEECOM004"]) {
            totalNSR = totalNSR + parseFloat(String(kbiValues["KEECOM004"]));
            totalNSRPrev = totalNSRPrev + parseFloat(String(kbiValuesPrevPeriod["KEECOM004"]));
            totalNSRBP = totalNSRBP + businessPlan?.["KEECOM004"]?.value;
        }
        if (kbiValues["KEECOM025"]) {
            totalNSR = totalNSR + parseFloat(String(kbiValues["KEECOM025"]));
            totalNSRPrev = totalNSRPrev + parseFloat(String(kbiValuesPrevPeriod["KEECOM025"]));
            totalNSRBP = totalNSRBP + (businessPlan?.["KEECOM025"]?.value || 0);
        }
        return { totalNSR, totalNSRPrev, totalNSRBP };
    };
    
    const getTotalVolume = () => {
        let totalVolume = 0;
        let totalVolumePrev = 0;
        let totalVolumeBP = 0;

        if (kbiValues["KEECOM005"]) {
            totalVolume = totalVolume + parseFloat(String(kbiValues["KEECOM005"]));
            totalVolumePrev = totalVolumePrev + parseFloat(String(kbiValuesPrevPeriod["KEECOM005"]));
            totalVolumeBP = totalVolumeBP + businessPlan?.["KEECOM005"]?.value;
        }
        if (kbiValues["KEECOM006"]) {
            totalVolume = totalVolume + parseFloat(String(kbiValues["KEECOM006"]));
            totalVolumePrev = totalVolumePrev + parseFloat(String(kbiValuesPrevPeriod["KEECOM006"]));
            totalVolumeBP = totalVolumeBP + businessPlan?.["KEECOM006"]?.value;
        }
        if (kbiValues["KEECOM007"]) {
            totalVolume = totalVolume + parseFloat(String(kbiValues["KEECOM007"]));
            totalVolumePrev = totalVolumePrev + parseFloat(String(kbiValuesPrevPeriod["KEECOM007"]));
            totalVolumeBP = totalVolumeBP + businessPlan?.["KEECOM007"]?.value;
        }
        if (kbiValues["KEECOM008"]) {
            totalVolume = totalVolume + parseFloat(String(kbiValues["KEECOM008"]));
            totalVolumePrev = totalVolumePrev + parseFloat(String(kbiValuesPrevPeriod["KEECOM008"]));
            totalVolumeBP = totalVolumeBP + businessPlan?.["KEECOM008"]?.value;
        }
        if (kbiValues["KEECOM026"]) {
            totalVolume = totalVolume + parseFloat(String(kbiValues["KEECOM026"]));
            totalVolumePrev = totalVolumePrev + parseFloat(String(kbiValuesPrevPeriod["KEECOM026"]));
            totalVolumeBP = totalVolumeBP + (businessPlan?.["KEECOM026"]?.value || 0);
        }
        return {totalVolume, totalVolumePrev, totalVolumeBP};
    };

    const getRelDiff = (num_one:number, num_two:number) => {
        return 100 * Math.abs((num_one - num_two) / ((num_one + num_two) / 2))
    }

    const getChange = (num_one:number | null, num_two:number | null) => {
        if (num_one != null && num_two != null) {
            return (num_one - num_two) / num_two * 100
        }
        return NaN;
    }

    return (
        <Col span={12}>
            <Spin tip="Loading" spinning={kbiValuesLoading} size="large">
                <Card>
                    <Row style={{ height: 60 }}>
                        <Col span={17} />
                        <Col span={4}>
                            <p className="user-view-kbi-unit">{`vs. ${prevPeriodTitle}`}</p>
                        </Col>
                        <Col span={3}>
                            <p className="user-view-kbi-unit">{`vs. BP`}</p>
                        </Col>
                    </Row>
                    {kbiMaster.map((kbi) => (
                        <>
                        {kbi.kbi_id === "KEECOM009" ? (
                            <>
                            <Row style={{ height: "60px" }}>
                                <Col span={5}>
                                    <p className="user-view-input-label-text">
                                        {kbi.title}
                                    </p>
                                </Col>
                                <Col span={9}>
                                    <input
                                        className="text-input-box"
                                        name={kbi.kbi_id}
                                        value={
                                            kbiValues?.[kbi.kbi_id]
                                                ? kbiValues[kbi.kbi_id]!
                                                : ""
                                        }
                                        onChange={inputOnChange}
                                        disabled={inputDisabled}
                                        type="text"
                                        step="any"
                                        min="0"
                                    />
                                </Col>
                                <Col span={3}>
                                    <p className="user-view-kbi-unit">{kbi.unit}</p>
                                </Col>
                                <Col span={4}>
                                    <p className="user-view-kbi-unit">
                                        {kbiValuesPrevPeriod[kbi.kbi_id] != null && kbiValuesPrevPeriod[kbi.kbi_id]! > -1
                                            && !isNaN(getChange(kbiValues[kbi.kbi_id], kbiValuesPrevPeriod[kbi.kbi_id]))
                                            && isFinite(getChange(kbiValues[kbi.kbi_id], kbiValuesPrevPeriod[kbi.kbi_id]))
                                            ? `${getChange(kbiValues[kbi.kbi_id], kbiValuesPrevPeriod[kbi.kbi_id]).toFixed(2)}%`
                                            : 'N/A'}
                                    </p>
                                </Col>
                                <Col span={3}>
                                    <p className="user-view-kbi-unit">
                                        {businessPlan?.[kbi.kbi_id]?.value > -1
                                            && !isNaN(getChange(kbiValues[kbi.kbi_id], businessPlan?.[kbi.kbi_id].value))
                                            && isFinite(getChange(kbiValues[kbi.kbi_id], businessPlan?.[kbi.kbi_id].value))
                                            ? `${getChange(kbiValues[kbi.kbi_id], businessPlan?.[kbi.kbi_id].value).toFixed(2)}%`
                                            : 'N/A'}
                                    </p>
                                </Col>
                            </Row>
                            {/* Total NSR(Only To Show) */}
                            <Row style={{ height: "60px" }}>
                                <Col span={5}>
                                    <p className="user-view-input-label-text">
                                        Total NSR
                                    </p>
                                </Col>
                                <Col span={9}>
                                    <input
                                        className="text-input-box-disabled"
                                        name="total_nsr"
                                        value={totalNSRValue || 0}
                                        // onChange={inputOnChange}
                                        disabled={true}
                                        type="number"
                                        step="any"
                                        min="0"
                                    />
                                </Col>
                                <Col span={3}>
                                    <p className="user-view-kbi-unit">('000 EUR)</p>
                                </Col>
                                <Col span={4}>
                                    <p className="user-view-kbi-unit">
                                        {!isNaN(getChange(totalNSRValue, totalNSRValuePrev)) && isFinite(getChange(totalNSRValue, totalNSRValuePrev))
                                            ? `${getChange(totalNSRValue, totalNSRValuePrev).toFixed(2)}%`
                                            : 'N/A'}
                                    </p>
                                </Col>
                                <Col span={3}>
                                    <p className="user-view-kbi-unit">
                                        {!isNaN(getChange(totalNSRValue, totalBPNSR)) && isFinite(getChange(totalNSRValue, totalBPNSR))
                                            ? `${getChange(totalNSRValue, totalBPNSR).toFixed(2)}%`
                                            : 'N/A'}
                                    </p>
                                </Col>
                            </Row>
                            {/* Total NSR(Only To Show) */}
                            {/* Total Volume(Only To Show) */}
                            <Row style={{ height: "60px" }}>
                                <Col span={5}>
                                    <p className="user-view-input-label-text">
                                        Total Volume
                                    </p>
                                </Col>
                                <Col span={9}>
                                    <input
                                        className="text-input-box-disabled"
                                        name="total_nsr"
                                        value={totalVolumeValue || 0}
                                        // onChange={inputOnChange}
                                        disabled={true}
                                        type="number"
                                        step="any"
                                        min="0"
                                    />
                                </Col>
                                <Col span={3}>
                                    <p className="user-view-kbi-unit">(UC '000)</p>
                                </Col>
                                <Col span={4}>
                                    <p className="user-view-kbi-unit">
                                        {!isNaN(getChange(totalVolumeValue, totalVolumePrevValue)) && isFinite(getChange(totalVolumeValue, totalVolumePrevValue))
                                            ? `${getChange(totalVolumeValue, totalVolumePrevValue).toFixed(2)}%`
                                            : 'N/A'}
                                    </p>
                                </Col>
                                
                                <Col span={3}>
                                    <p className="user-view-kbi-unit">
                                        {!isNaN(getChange(totalVolumeValue, totalBPVolume)) && isFinite(getChange(totalVolumeValue, totalBPVolume))
                                            ? `${getChange(totalVolumeValue, totalBPVolume).toFixed(2)}%`
                                            : 'N/A'}
                                    </p>
                                </Col>
                            </Row>
                            {/* <Row justify="center">
                                <Typography.Title
                                    level={4}
                                    style={{ color: "#E61C2A" }}
                                >
                                    Market Share Per Category
                                </Typography.Title>
                            </Row> */}
                            {/* Total Volume(Only To Show) */}
                            </>
                        ) : (
                            <Row style={{ height: "60px" }}>
                                <Col span={5}>
                                    <p className="user-view-input-label-text">
                                        {kbi.title}
                                    </p>
                                </Col>
                                <Col span={9}>
                                    <input
                                        className="text-input-box"
                                        name={kbi.kbi_id}
                                        value={
                                            kbiValues?.[kbi?.kbi_id] != null && kbiValues?.[kbi?.kbi_id]! > -1
                                                ? kbiValues[kbi.kbi_id]!
                                                : ''
                                        }
                                        onChange={inputOnChange}
                                        disabled={inputDisabled}
                                        type="text"
                                        step="any"
                                        min="0"
                                    />
                                </Col>
                                <Col span={3}>
                                    <p className="user-view-kbi-unit">{kbi.unit}</p>
                                </Col>
                                <Col span={4}>
                                    <p className="user-view-kbi-unit">
                                        {!isNaN(getChange(kbiValues[kbi.kbi_id], kbiValuesPrevPeriod[kbi.kbi_id])) && isFinite(getChange(kbiValues[kbi.kbi_id], kbiValuesPrevPeriod[kbi.kbi_id]))
                                            ? `${getChange(kbiValues[kbi.kbi_id], kbiValuesPrevPeriod[kbi.kbi_id]).toFixed(2)}%`
                                            : 'N/A'}

                                        {/* {getRelDiff(kbiValues[kbi.kbi_id], kbiValuesPrevPeriod[kbi.kbi_id])} */}

                                    </p>
                                    {/* <p className="user-view-kbi-unit">
                                    {kbiValuesPrevPeriod[kbi.kbi_id]}
                                    </p> */}
                                </Col>
                                {kbi.kbi_id !== 'KEECOM027' && <Col span={3}>
                                    <p className="user-view-kbi-unit">
                                        {!isNaN(getChange(kbiValues[kbi.kbi_id], businessPlan?.[kbi.kbi_id]?.value)) && isFinite(getChange(kbiValues[kbi.kbi_id], businessPlan?.[kbi.kbi_id]?.value))
                                            ? `${getChange(kbiValues[kbi.kbi_id], businessPlan?.[kbi.kbi_id]?.value).toFixed(2)}%`
                                            : 'N/A'}
                                    </p>
                                </Col>}
                            </Row>
                        )}
                        </>
                    ))}
                </Card>
            </Spin>
        </Col>
    );
}

export default KBICard;